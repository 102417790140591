
import React from "react";
import Natarajdance from "../images/dance.jpg";
import Natarajactine from "../images/ourshootingtime.jpg";
import Natarajmodeling from "../images/MODELING.jpg";
import Navbar from "../inc/Navbar";
function Services () {
    return(
            <>

            <Navbar/>

            <br/>
            <br/>
            <br/>
            <br/>
            
            <div className="album py-4">
        
        <div className="container">
        
            <div className="row">
            
            <div className="col-md-6 mt-8" >

                       
            <h2 className="card-text"> <b>DANCE</b></h2>
                                
                                <h6 className="card-text"> <b>NATARAJ CULTURAL UNIT</b></h6>
                                <p className="card-text">Dance is taught here
</p>
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <br/>
                            <div className="col-md-6 mt-8" >
                        
                        <img src={Natarajdance} className="card-img-top"    alt="Natarajdance" />
                           
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                
                           
                            </div>
                               
                            </div>
                      
                            
                           
                           
                            
                               
                            </div>
                         
                     
                            </div>
                 
                            </div>
                         <hr/> 

                         <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >

                       
            <img src={Natarajactine} className="card-img-top"   alt="Natarajactine" />
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-5 mt-6" >
                        
                                <h1 className="card-text"> <b>ACTING</b></h1>
                                <h6 className="card-text"> <b>NATARAJ CULTURAL UNIT</b></h6>
                                <p className="card-text">Shooting is tought here, Best platform in West Bengal</p>
                              
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>

<hr/>
<div className="album py-4">
        
        <div className="container">
        
            <div className="row">
            
            <div className="col-md-6 mt-8" >

                       
            <h2 className="card-text"> <b>MODELLING</b></h2>
                                
                                <h6 className="card-text"> <b>NATARAJ CULTURAL UNIT</b></h6>
                                <p className="card-text">Modelling is taught here, 
</p>
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <br/>
                            <div className="col-md-6 mt-8" >
                        
                        <img src={Natarajmodeling} className="card-img-top"    alt="Natarajmodeling" />
                           
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                
                           
                            </div>
                               
                            </div>
                      
                            
                           
                           
                            
                               
                            </div>
                         
                     
                            </div>
                 
                            </div>
                         <hr/> 
            </>

            
    );
}

export default Services;