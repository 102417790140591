import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import {db} from '../../firebase'

import ScrollToTop from "react-scroll-to-top";



import ReactPlayer from "react-player";

function Videonew (props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [newv, setNewv] = useState([])
      const navigate = useNavigate()
  
      const newvCollectionRef = collection(db, "Video");
      useEffect(() => {
  
          const getNewv = async () => {
              const data = await getDocs(newvCollectionRef);
              setNewv(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getNewv()
      }, [])
    return (
      
<>

<ScrollToTop smooth top="100" color="maroon"/>
<br/>

<div className="album py-0">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search Episode.." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {newv.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      
       
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((danceschool) => { return (
       
            <div className="col-md-4 mt-3" >
                        <div className=" mb-4">
                                  <ReactPlayer className="mb-3"  width='340px' height='240px' url={danceschool.video}/>
                        {/* <img  className="card-img-top" src={danceschool.Img}   alt="Singer" /> */}
                            <div className="card-body">
                                
                                <h6 style={{color:"darkblue"}} className="card-text mt-3"> <b>{danceschool.title}</b></h6>
                                <p className="card-text">{danceschool.episode}</p>
                               
                    
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={videop.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Videonew;