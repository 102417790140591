import React, { useState, useEffect }  from 'react';
import {useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import {db} from '../../firebase'
// import Doctors from '../images/33333.jpg';
import ScrollToTop from "react-scroll-to-top";
import Navbar from "../inc/Navbar";

function Danceshow() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(54);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [danceshow, setDanceshow] = useState([])
    const navigate = useNavigate()

    const danceshowCollectionRef = collection(db, "danceshow");
    useEffect(() => {

        const getDanceshow = async () => {
            const data = await getDocs(danceshowCollectionRef);
            setDanceshow(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getDanceshow()
    }, [])
    return (
<>
  <ScrollToTop smooth top="100" color="Darkblue"/>
{/* <ScrollToTop smooth top="100"/> */}
{/* <img src={ Doctors} class="img-fluid"   alt="Doctors"></img> */}

<Navbar/>
<br/>
<br/>
<br/>
<br/>
<br/>
<center><h2 style={{color:"Darkblue"}} ><b>NATARAJ DANCE SHOW</b></h2></center>
<div className="album py-0">
        
        <div className="container">

            <div className="row">
            <div className="col-md-6">
                    
                    <div className="card-body">
                   
                            <div className="d-flex justify-content-between align-items-center">
                               
                                
                        

                        </div>
                      
                
                    </div>

                  
                </div>
            
                </div>
                </div>
                </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {danceshow.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } 
      
      {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((natarajculturalunit) => { return (
       
            <div className="col-md-4 mt-4" >
                        <div className="card mb-4 border- box shadow">
                        <img  className="card-img-top" src={natarajculturalunit.img}   alt="Nataraj-Cultural-Unit" />
                         
                                
                                
                            
                                                          
                                      

                                       
                                    
                              
                              
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                    
                            </div>
                            </div>
</>

);
}

export default Danceshow;
