

import React from "react";
import {Link} from "react-router-dom";
import "./Navbar.css";
function Navbar () {
    return(
            <>
            <div className="fixed-top">
            <div className="nataraj">
        <div className="album py-1">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-5" >
            <div className="d-flex justify-content-between align-items-center">
              <Link to="/achivement">
            <button style={{pading: "2px"}} className="btn btn-danger btn-sm"> Our Achivements</button>
            </Link>
                               
                         
                              
                     <Link to="/online">         
            <button   style={{pading: "2px"}} className="btn btn-light btn-sm"> Online Admission <div className="spinner-grow text-warning spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div></button>
            </Link>
           
                               
                        
                            </div>
                               
                            </div>
                          
                            <div className="d-flex justify-content-between align-items-center">
                           


                
                         
                            </div>
                            </div>
                            </div>
                            </div>
        </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <div className="asstyle">
    <Link to="/" className="navbar-brand" href="#"><b style={{color:"white"}} >Nataraj Cultural Unit</b> </Link>
    </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" style={{color:"white"}} className="nav-link active" aria-current="page" href="#"><b style={{color:"white"}}>Home</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/services" className="nav-link active" aria-current="page" href="#" ><b style={{color:"white"}}>Our Class</b></Link>
        </li>
       
       
        <li className="nav-item">
          <Link to="/about" className="nav-link active" aria-current="page" href="#"><b style={{color:"white"}}>About Us</b></Link>
        </li>

        <li className="nav-item">
          <Link to="/achivement" className="nav-link active" aria-current="page" ><b style={{color:"white"}}>Achivement</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/danceshow" className="nav-link active" aria-current="page" href="#"><b  style={{color:"white"}}>Dance Show</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/event-management" className="nav-link active" aria-current="page" href="#"><b style={{color:"white"}}>Event Management</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/portfolio" className="nav-link active" aria-current="page" href="#"><b style={{color:"white"}}>Portfollio</b></Link>
        </li>
       
        <li className="nav-item">
          <Link to="/contact" className="nav-link active" aria-current="page" href="#"><b style={{color:"white"}}> Contact Us</b></Link>
        </li>
      </ul>
  
    </div>
  </div>
</nav>
</div>
            </>
    );
}

export default Navbar;