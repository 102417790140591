



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyAd9OOSnk05dsbzfbaL5Mpm9Wxyb0EKpbw",
    authDomain: "nataraj-cultural-unit.firebaseapp.com",
    projectId: "nataraj-cultural-unit",
    storageBucket: "nataraj-cultural-unit.appspot.com",
    messagingSenderId: "734256966642",
    appId: "1:734256966642:web:dc201a0a56a294afaad96b",
    measurementId: "G-2EZ0EQ4C0W"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



