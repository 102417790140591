

import React from "react";
import Navbar from "../inc/Navbar";
import { Typewriter } from 'react-simple-typewriter' 
import Nataraj from "../images/celebrity.jpg";
import Nataraj1 from "../images/celebrity1.jpg";
import Nataraj2 from "../images/celebrity2.jpg";
import Nataraj3 from "../images/celebrity3.jpg";
import Nataraj4 from "../images/celebrity4.jpg";
import {  Button } from "react-bootstrap";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import ngImage from "../images/didi.mp4";
import mgImage from "../images/mg.mp4";
import Slider from "../inc/Slider";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./Home.css";
import Videonew from "./Videonew";


function Home () {
    const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return(
            <>
            <Navbar/>
            <br/>
            <br/>
            <ScrollToTop smooth top="100" color="Darkblue"/>
            <div style={{color: "Gray", padding:"14px;"}} className="fixed-bottom "> <a href="tel: +91 7384375944" className="btn btn-info" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div> </a></div>

            {/* <div className="album py-2">
        
        <div className="container">

            <div className="row">
                <h5>MR. INDIAN CODER</h5>
                </div>
                </div>
                </div> */}
                <div style={{  
  backgroundImage: "url(" + "https://i.postimg.cc/cJHXxvf9/DSC-0316.jpg" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
    
   
  
     <div className="album py-0">
        
        <div className="container">

            <div className="row">
                
               
               
            </div>
            </div>
            </div>
                 <br/> 
                 <br/>
                 <br/>
                 <br/>
                 <br/> 
                 <br/>
                 <br/>
                 <br/>
                 <br/> 
                 <br/>

            
                
            
                  <br/>
                  <br/>
                  <br/>
                 <div className="album py-0">
        
        <div className="container">

            <div className="row">
               
            
      < h5 className="card-text mb-2 " style={{ margin: "auto 0", color: "white"}}> <b> Our Class :  <span style={{ fontWeight: "bold", marginleft: '10px',color: "white"}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={100}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ "Dance", "Acting", "Modelling", "Drwaing", "Music"]}
    />
</span>
</b></h5>
                 </div>
                 </div>
                 </div>
              

        
            
                 <div className="album py-0">
        
        <div className="container">

            <div className="row">
                <div className="tech">
               
               
                <h1 style={{ fontSize: "4rem", color:"white"}} className="card-text mb-8"> <b>NATARAJ CULTURAL UNIT</b></h1>
                </div>
            </div>
            </div>
            </div>
                
            <br/>
                               
                 
     </div>
      <div className="natarajcultural">

      
      
      <center><h1><b> Best Dance School in Berhampore</b></h1>
      </center>
   
     
      </div>
      <Videonew/>
     <div className="album py-2">
        
        <div className="container">
            <div className="row">
              <div className="fontnataraj">
              <h2><b style={{color:"Darkblue"}}> Nataraj Cultural Unit Students Explore </b></h2>
              </div>
              </div>
              </div>
            </div>
            <div className="album py-2">
        <div className="container">

            <div className="row">
               <div className="col-md-4 ">
               <div class="card   mb-4">
                      
                      <Link to="/about">
                      <div className="d-grid gap-3">
                      
                      <button className="btn btn-outline-secondary">About</button>
                      
                      </div>
                      </Link>
                      <Link to="/achivement">
                      <div className="d-grid gap-2">
                                                <Button variant="outline-danger" type="Submit">
                                                   Achivement
                                                </Button>
                                            </div>
                                            </Link>
                                            <Link to="/danceshow">
                      <div className="d-grid gap-2">
                                                <Button variant="outline-primary" type="Submit">
                                                   Dance Show
                                                </Button>
                                            </div>
                                            </Link>
                                            <Link to="/event-management">
                      <div className="d-grid gap-2">
                                                <Button variant="outline-success" type="Submit">
                                               Event Management 
                                                </Button>
                                            </div>
                                            </Link>
                    
                      <Link to="/portfolio">
                      <div className="d-grid gap-2">
                      
                                                <Button variant="outline-warning" type="Submit">
                                  Portfolio
                                                </Button>
                                                
                                            </div>
                                            </Link>
                       
                                 
                        
                    </div>
</div>
            <div className="col-md-4">
            <div className="card mb-4 border-White box shadow">
<Video  loop  onCanPlayThrough={()=>{
    console.log('video play')
}}>
<source src={ngImage}  type="video/webm"/>

</Video>
<div className="card-body">
<h5>M.G এর হাত ফ্ল্যাগ পাওয়ার মুহূর্ত </h5>
</div>
</div>
</div>
<div className="col-md-4">
<div className="card mb-4 border-White box shadow">
<Video  loop  onCanPlayThrough={()=>{
    console.log('video play')
}}>
  
<source src={mgImage}  type="video/webm"/>

</Video>

<div className="card-body">

<h5>NATARAJ CULTURAL UNIT এর  শিশু শিল্পী অঙ্গীরা Performance Zee Bengla TV channel এর দিদি নম্বর ১ এ </h5>
</div>
</div>
</div>
</div></div>
</div>



     
                    <div className="containers">
                        <center>
                        <h1><b>Nataraj Cultural Unit</b></h1>
                        <h6>Best Dance School in Berhampore</h6>
                        </center>
                    </div>
                    <img src={Nataraj} className="img-fluid" alt="Celebritywithnatarjculturalunit"/>
                    <div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Nataraj1} alt="Nataraj-Cultural-Unit"/>
  <div className="card-body text">
  <div className="alert alert-primary " role="alert">

    <center>
    <h6 className="card-title mb-0"><b>Nataraj Cultural Unit </b></h6>
    <p className="card-text"><small>Description.....</small></p>
    </center>
    </div>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Nataraj2} alt="Nataraj-Cultural-Unit"/>
  <div className="card-body text">
  <div className="alert alert-danger " role="alert">
    <center>
    <h6 className="card-title mb-0"><b>Nataraj Cultural Unit </b></h6>
    <p className="card-text"><small>Description.....</small></p>
    </center>
    </div>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Nataraj3} alt="Nataraj-Cultural-Unit"/>
  <div className="card-body text">
  <div className="alert alert-info " role="alert">
    <center>
    <h6 className="card-title mb-0"><b>Nataraj Cultural Unit </b></h6>
    <p className="card-text"><small>Description.....</small></p>
    </center>
    </div>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Nataraj4} alt="Nataraj-Cultural-Unit"/>
  <div className="card-body text">
  <div className="alert alert-warning" role="alert">
    <center>
    <h6 className="card-title mb-0"><b>Nataraj Cultural Unit </b></h6>
    <p className="card-text"><small>Description.....</small></p>
    </center>
    </div>
  </div>
</div>




</Carousel>
</div>
</div>

</div>
<br/>
            
               
               <Slider/>
            </>
    );
}

export default Home;