
import React from "react";
import Navbar from "../inc/Navbar";
import AddAdmission from "../AddAdmission";
import NatarajCultural from "../images/imagenataraj.jpg";
function Online () {
    return(
            <>
            <Navbar/>
            <br/>
            <br/>
            <img src={NatarajCultural} className="img-fluid" alt="Celebritywithnatarjculturalunit"/>
            <AddAdmission/>

            </>

            );
        }
        
        export default Online;