


import React from "react";
import About from "../images/about.jpeg";
import ScrollToTop from "react-scroll-to-top";
import Navbar from "../inc/Navbar";
function Aboutus() {
    return(
            <>

            <Navbar/>

            <br/>
            <br/>
            <br/>
            <br/>
            
            <ScrollToTop smooth top="100" color="Darkblue"/>
            <div className="album py-4">
        
        <div className="container">
        
            <div className="row">
            
            <div className="col-md-6 mt-8" >

                       
           
            <img src={About} className="card-img-top"    alt="Natarajmodeling" />
                              
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <br/>
                            <div className="col-md-6 mt-8" >
                            <h2 className="card-text"> <b>Avigyan Bhattacherjya</b></h2>
                      
                            <h6 className="card-text"> <b>DIRECTOR</b></h6>
                                <p className="card-text">Director Speech........ 
</p>
           
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                
                           
                            </div>
                               
                            </div>
                      
                            
                           
                           
                            
                               
                            </div>
                         
                     
                            </div>
                 
                            </div>
                         <hr/> 
                 </>

            
);
}

export default Aboutus;