
import './App.css';

import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import Achivement from "./components/pages/Achivement";
import Danceshow from "./components/pages/Danceshow";
import Event from "./components/pages/Event";
import Portfolio from "./components/pages/Portfolio";
import Online from "./components/pages/Online";
import Footer from "./components/inc/Footer";

function App() {
  return (
    <Router>
      
  
    
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/about" element={<About/>}/>
          <Route axact path="/services" element={<Services/>}/>
          <Route axact path="/achivement" element={<Achivement/>}/>
          <Route axact path="/danceshow" element={<Danceshow/>}/>
          <Route axact path="/event-management" element={<Event/>}/>
          <Route axact path="/portfolio" element={<Portfolio/>}/>
          <Route axact path="/online" element={<Online/>}/>
          </Routes>
    <Footer/>
  
    </div>
   
    </Router>
        
  );
}

export default App;
